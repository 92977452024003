import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { handleTag, ModalBase, ModalContent } from '@saatva-bits/pattern-library.modules.detail-modal'
import CATEGORIES from '@/temp-configs/category-constants'

import Gallery from './partials/Gallery'
import ProductSummary from './partials/ProductSummary'

import { AccordionSet } from '@saatva-bits/pattern-library.components.accordion-set'
import { Accordion } from '@saatva-bits/pattern-library.components.accordion'

import styles from './DetailModalVariant.module.scss'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

/**
 * THIS IS BASICALLY THE DETAIL MODAL WRAPPER EXPOSED BY THE DETAIL MODAL MODULE
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {function} props.closeHandler
 * @param {object} props.productData
 * @param {import('react').ReactNode} props.buystack
 * @param {object} props.promotionDeps
 * @param {object} props.selectionDeps
 * @param {object} props.addToCartSection
 * @returns
 */
const DetailModalVariant = ({
    isOpen,
    closeHandler,
    productData,
    buystack,
    promotionDeps,
    selectionDeps,
    addToCartSection
}) => {
    const [noTransitionClass, setNoTransitionClass] = useState(true)
    const isBeddingCategory = productData.category.toLowerCase() === CATEGORIES.BEDDING
    const isBundleCategory = productData.category.toLowerCase() === 'bundles'
    if (!productData) {
        return null
    }

    const productTag = handleTag(productData.isNew, productData.tag)

    const quickViewSections = productData.quickviewSections?.filter((section) => section?.name && section?.content)
    const accordionData = quickViewSections.map((section, index) => ({
        id: section.name,
        title: section.name,
        content: <div className={styles.accordionContent} dangerouslySetInnerHTML={{ __html: section.content }} />,
        isOpenOnRender: isBeddingCategory || isBundleCategory ? index === 0 : true,
        dataSelector: `${section.name.replace(/\s+/g, '').toLowerCase()}-accordion`
    }))

    const accordionTransitionClasses = classNames({
        [styles.accordionNoTransition]: noTransitionClass
    })

    const accordionClickHandler = () => {
        if (noTransitionClass) {
            setNoTransitionClass(false)
        }
    }

    const modalCloseHandler = () => {
        closeHandler()
        setNoTransitionClass(true)
    }

    return (
        <ModalBase
            type="popup"
            isOpen={isOpen}
            closeHandler={modalCloseHandler}
            modalClasses={styles.modal}
            closeButtonClasses={styles.closeButton}
            contentClasses={styles.modalBaseContainer}
        >
            <ModalContent
                classes={styles.modalContent}
                mainClasses={styles.mainClasses}
                contentClasses={styles.contentClasses}
                main={
                    <>
                        <Gallery
                            IMGIX_URL={IMGIX_URL}
                            selectionDeps={selectionDeps}
                            productCode={productData.productCode}
                            productTag={productTag}
                        />
                    </>
                }
                content={
                    <>
                        <ProductSummary
                            promotionDeps={promotionDeps}
                            selectionDeps={selectionDeps}
                            title={productData.title}
                            subtitle={productData.subtitle}
                            product={{ ...productData }}
                            selectedSizes={productData.selectedSizes}
                        />
                        {buystack}
                        <AccordionSet accordionData={accordionData} onAccordionItemClick={accordionClickHandler}>
                            <Accordion
                                className={styles.accordion}
                                contentContainerClassName={styles.accordionContentContainer}
                                contentClassName={accordionTransitionClasses}
                                classNameModifier='bgContrastWhite'
                                titleContainerClassName="t-color"
                                titleClassName={styles.accordionTitle}
                                iconClassName={styles.accordionIcon}
                                allowMultiOpen
                            />
                        </AccordionSet>
                        {addToCartSection}
                    </>
                }
            />
        </ModalBase>
    )
}

DetailModalVariant.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeHandler: PropTypes.func.isRequired,
    productData: PropTypes.object,
    buystack: PropTypes.node,
    selectionDeps: PropTypes.shape({
        useProductState: PropTypes.func.isRequired,
        useProductData: PropTypes.func.isRequired
    }).isRequired,
    promotionDeps: PropTypes.shape({
        useGetDiscounts: PropTypes.func.isRequired,
        applyDiscounts: PropTypes.func.isRequired
    }),
    addToCartSection: PropTypes.node
}

export default DetailModalVariant
