import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@saatva-bits/pattern-library.components.icon'

import styles from './QuantityInput.module.scss'

/**
 *
 * @param {object} props
 * @param {object} props.quantityAttribute
 * @param {string} props.dataSelector
 * @returns
 */
const QuantityInput = ({ quantityAttribute, dataSelector }) => {
    const quantity = Number(quantityAttribute.selectedValue)
    const maxQuantity = quantityAttribute.values.length

    const handleIncreaseQuantity = () => {
        quantityAttribute.setAttributeValue(`${quantity + 1}`)
    }

    const handleDecreaseQuantity = () => {
        quantityAttribute.setAttributeValue(`${quantity - 1}`)
    }

    return (
        <div className={styles.buttonsContainer} data-selector={`${dataSelector}_element`}>
            <button
                className={styles.button}
                onClick={() => handleDecreaseQuantity()}
                data-selector={`${dataSelector}_decreaseBtn`}
                disabled={quantity === 1}
            >
                <Icon
                    alt='decrease quantity'
                    description="decrease quantity"
                    name='less'
                    titleId="decreaseQty"
                />
            </button>
            <span className={styles.quantity} data-selector={`${dataSelector}_quantityCounter`}>{quantity}</span>
            <button
                className={styles.button}
                onClick={() => handleIncreaseQuantity()}
                data-selector={`${dataSelector}_increaseBtn`}
                disabled={quantity > maxQuantity - 1}>
                <Icon
                    alt='increase quantity'
                    description="increase quantity"
                    name='more'
                    titleId="increaseQty"
                />
            </button>
        </div>
    )
}

QuantityInput.propTypes = {
    quantityAttribute: PropTypes.number.isRequired,
    dataSelector: PropTypes.string
}

export default QuantityInput