import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '@saatva-bits/pattern-library.components.button'

import styles from './QuizOnRamp.module.scss'

const QuizOnRamp = ({
    sectionClassName,
    containerClassName,
    contentClassName,
    titleClassName,
    buttonClassName,
    showCompareCTA = true,
    ctaText = 'Take our Mattress Quiz',
    showQuizCTA = true
}) => {
    const sectionClassNames = classNames(
        'section',
        styles.section,
        sectionClassName
    )

    const containerClasses = classNames(
        'container',
        styles.container,
        containerClassName
    )

    const contentClassNames = classNames(
        styles.content,
        contentClassName
    )

    const titleClassNames = classNames(
        't-heading2 t-color--white',
        styles.title,
        titleClassName
    )

    const buttonClassNames = classNames(styles.button , buttonClassName)

    const compareClassNames = classNames(styles.button, {
        [styles['button--compareCTA']]: showQuizCTA
    })

    return (
        <section className={sectionClassNames}>
            <div className={containerClasses} >
                <div className={contentClassNames}>
                    <h2 className={titleClassNames}>
                        Need help finding the right mattress for you?
                    </h2>
                    <div>
                        { showQuizCTA && (
                            <Button
                                kind="primary"
                                className={buttonClassNames}
                                href="/mattress-quiz"
                                data-selector='quizOnRampCTA'
                            >
                                {ctaText}
                            </Button>
                        )}
                        {showCompareCTA && (
                            <Button
                                kind={showQuizCTA ? 'secondary' : 'primary'}
                                className={compareClassNames}
                                href="/mattresses/compare"
                                data-selector='compareOnRampCTA'
                            >
                                Compare all mattresses
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}


QuizOnRamp.propTypes = {
    sectionClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    showCompareCTA: PropTypes.bool,
    ctaText: PropTypes.string
}

export default QuizOnRamp
