import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Badge } from '@saatva-bits/pattern-library.components.badge'
import { Picture } from '@saatva-bits/pattern-library.components.picture-imgix'

import styles from './ProductImage.module.scss'

/**
 * Taken from detail modal module to not bump packages there
 *
 * The tweak here is for implementing the gallery easier and also for
 * allowing to choose if the image reacts to the buystack swatch selections or not
 * @param {object} props
 * @param {string} props.productCode
 * @param {string} props.alt - Alt if image doesnt show up
 * @param {string} props.tag - Tag string text
 * @param {bool} props.useSelectedColor - take into account user swatch selection to render matching picture
 * @param {string} props.IMGIX_URL - imgix domain url to retrieve image
 * @param {object} props.selectionDeps - Selection context functions used internally
 * @param {string} props.containerClasses - Class overrides for container
 * @param {string} props.imageClasses - class overrides for img element
 * @param {string} props.pictureClasses - class overrides for picture element
 * @param {import('../../typedefs').UseProductState} props.selectionDeps.useProductState - returns the requested attributes current selected variant
 * @param {import('../../typedefs').UseProductData} props.selectionDeps.useProductData - returns the product data
 */
const ProductImage = ({
    alt,
    tag,
    filename,
    folder,
    dataSelector,
    containerClasses,
    imageClasses,
    pictureClasses,
    IMGIX_URL = 'saatva-qa.imgix.net',
}) => {
    const containerClassnames = classNames(styles.imageContainer, containerClasses)
    const pictureClassnames = classNames(styles.imagePicture, pictureClasses)
    const imageClassnames = classNames(styles.image, imageClasses)
    return (
        <div className={containerClassnames}>
            <Picture
                lazyLoad
                alt={alt}
                folder={folder}
                name={filename}
                imgixDomain={IMGIX_URL}
                dataSelector={dataSelector}
                imageClassName={imageClassnames}
                className={pictureClassnames}
                prefixOverride={{
                    mobile: 'none',
                    tablet: 'none',
                    desktop: 'none'
                }}
            />
            {tag && (
                <Badge text={tag} />
            )}
        </div>
    )
}

ProductImage.propTypes = {
    IMGIX_URL: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    folder: PropTypes.string.isRequired,
    tag: PropTypes.string,
    alt: PropTypes.string,
    containerClasses: PropTypes.string,
    imageClasses: PropTypes.string,
    pictureClasses: PropTypes.string 
}

export default ProductImage