import { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { pushDataLayerEvent } from '@/utils/datalayer'

import styles from './SoldOutMessage.module.scss'

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.productName
 * @param {boolean} props.isMobile - Sets component as display fixed and moves it to bottom of screen, manual set
 * as we dont want to apply it everywhere
 * @returns 
 */
const SoldOutMessage = ({
    className,
    productName,
    isMobile
}) => {
    useEffect(() => {
        pushDataLayerEvent({
            'event': 'outOfStock'
        })
    }, [])

    const soldOutTextSorry = 'Sorry, the'
    const soldOutTextPleaseCall = `is low in stock, please call us at <a class="t-link ${styles['soldOutMessageLink']}" href="tel:1-877-672-2882">1-877-672-2882</a> for availability.`

    const classes = classNames(
        styles['soldOutMessage'],
        { [styles['soldOutMessage-mobile']]: isMobile },
        className
    )

    const detailedMessage = <>
        {soldOutTextSorry} {productName} <span dangerouslySetInnerHTML={{ __html: soldOutTextPleaseCall }}></span>
    </>

    return (
        <div className={classes}>
            {productName ? detailedMessage : 'This item is currently out of stock.'}
        </div>
    )
}

SoldOutMessage.propTypes = {
    className: PropTypes.string,
    productName: PropTypes.string,
    /** switch to a mobile layout where its rendered at the bottom */
    isMobile: PropTypes.bool
}

export default SoldOutMessage
